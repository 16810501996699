import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { IonImg } from "@ionic/react";
import { Button, Stack } from "@mui/material";
import { GoogleMap, Marker, OverlayView } from "@react-google-maps/api";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { convertToGoogleMapsLocation, getDeviceGeoLocation } from "@src/appV2/Location";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { InlineLoader } from "@src/lib/deprecatedCode";
import { Facility } from "@src/lib/interface";
import { useCallback, useEffect, useRef, useState } from "react";

import { MAP_EVENTS } from "./events";
import { MemoizedFacilitiesMarkers } from "./Facilities/Markers";
import {
  createCustomAlert,
  createCustomControl,
  defaultlongLat,
  googleMapContainerStyle,
  googleMapOptions,
  markersSourcePath,
} from "./googleMapShifts.const";
import { formatAgentAddress } from "./utils/formatAgentAddress";
import { USER_EVENTS } from "../../../constants";
import { useGoogleMapContext } from "../../context/googleMapContext";

interface GoogleMapShiftsProps {
  segmentView: string;
  facilityShiftsAreLoading: boolean;
  facilityShifts: Facility[];
  onClickHcfMarker: (facility: Facility) => void;
  onClickFindShiftsButton: (coordinates: number[]) => void;
  currentMapCenterCoordinates: number[];
}

/**
 * FIXME: Add tests to this component
 */
export function GoogleMapShifts(props: GoogleMapShiftsProps) {
  const {
    segmentView,
    facilityShiftsAreLoading,
    facilityShifts,
    onClickHcfMarker,
    onClickFindShiftsButton,
    currentMapCenterCoordinates,
  } = props;
  const {
    isMapLoaded,
    mapLoadError,
    googleMapCenter,
    setGoogleMapCenter,
    googleMapZoomLevel,
    setGoogleMapZoomLevel,
  } = useGoogleMapContext();
  const [isDoneInitBounds, setIsDoneInitBounds] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [boundCount, setBoundCount] = useState(0);
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [findShiftsButtonEnabled, setFindShiftsButtonEnabled] = useState(false);
  const ldFlags = useCbhFlags();
  const isNoFacilityMapAlertEnabled = ldFlags[CbhFeatureFlag.ENABLE_NO_FACILITY_MAP_ALERT];
  const facilityDistanceThresholdConfig =
    ldFlags[CbhFeatureFlag.FACILITY_DISTANCE_THRESHOLD_MAP_VIEW_CONFIG];
  const isBrowsingShiftsEnabled = ldFlags[CbhFeatureFlag.ENABLE_BROWSING_SHIFTS_ACROSS_US_MAP_VIEW];
  const [isShowHomePinDesc, setIsShowHomePinDesc] = useState<boolean>(false);
  const zoomInAfterFindingShifts = useRef(false);
  const worker = useDefinedWorker();
  const onBoundsChanged = () => {
    if (map && !facilityShiftsAreLoading) {
      const visible = areThereVisibleFacilitiesInBound();
      if (!visible) {
        setBoundCount((count) => count + 1);
      } else {
        setBoundCount(0);
      }
      createCustomAlert(map, visible, boundCount);
    }
  };

  const onLoadGoogleMap = async (gMap: google.maps.Map) => {
    // Load the Google Map Custom Control
    // Read https://developers.google.com/maps/documentation/javascript/examples/control-custom
    createCustomControl(
      gMap,
      computeGoogleMapCurrentLocation,
      computeGoogleMapHome,
      updateMapBounds
    );
    if (facilityShifts?.length < 1 || isDefined(googleMapCenter)) {
      gMap.setZoom(googleMapZoomLevel);
      gMap.setCenter(googleMapCenter ?? computeGoogleMapHome());
    }
    setMap(gMap);
  };
  const clearEventListeners = () => {
    // cloning element will remove eventListeners
    const controlUi = document.getElementById("iconLocationDiv");
    if (controlUi?.parentNode) {
      const newControlUi = controlUi.cloneNode(true);
      controlUi.parentNode.replaceChild(newControlUi, controlUi);
    }
  };

  useEffect(() => {
    return () => {
      clearEventListeners();
      setIsShowHomePinDesc(false);
    };
  }, []);

  useEffect(() => {
    if (zoomInAfterFindingShifts.current) {
      const zoomLevel = map?.getZoom();
      if (!facilityShiftsAreLoading && isDefined(zoomLevel) && zoomLevel < 10) {
        map?.setZoom(10);
        zoomInAfterFindingShifts.current = false;
      }
    }
  }, [facilityShifts, facilityShiftsAreLoading, map]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const computeGoogleMapHome = useCallback(() => {
    let center = defaultlongLat;
    if (worker.geoLocation?.coordinates) {
      center = {
        lng: worker.geoLocation.coordinates[0],
        lat: worker.geoLocation.coordinates[1],
      };
    }
    return center;
  }, [worker]);

  const updateMapBounds = useCallback(() => {
    if (
      !facilityShiftsAreLoading &&
      map?.fitBounds &&
      window?.google?.maps &&
      facilityShifts?.length > 0 &&
      segmentView === "map" &&
      isDoneInitBounds === false
    ) {
      if (isDefined(googleMapCenter)) {
        map.setCenter(googleMapCenter);
        map.setZoom(googleMapZoomLevel);
      } else {
        makeMapBoundsIntoHcf({
          facilityShifts,
          center: computeGoogleMapHome(),
          map,
          googleMaps: window.google.maps,
        });
      }
      setIsDoneInitBounds(true);
    }
  }, [
    facilityShiftsAreLoading,
    facilityShifts,
    map,
    googleMapCenter,
    googleMapZoomLevel,
    computeGoogleMapHome,
    segmentView,
    isDoneInitBounds,
  ]);

  useEffect(() => {
    if (map) {
      updateMapBounds();
    }
  }, [map, updateMapBounds]);

  const computeGoogleMapCurrentLocation = async () => {
    logEvent(MAP_EVENTS.REQUESTED_LOCATION_PERMISSION);
    try {
      const { geoLocation } = await getDeviceGeoLocation();
      const center = convertToGoogleMapsLocation(geoLocation);
      logEvent(MAP_EVENTS.GRANTED_LOCATION_PERMISSION);
      setCurrentLocation(center);
      return center;
    } catch {
      logEvent(MAP_EVENTS.DECLINED_LOCATION_PERMISSION);
    }
  };

  /**
   * This function enables the find shifts button
   * if the distance from current map center is > threshold
   * given in the LDFF FACILITY_DISTANCE_THRESHOLD_MAP_VIEW_CONFIG.
   * Otherwise it disables the button
   */
  const onMapCenterChanged = () => {
    const currentPosition = map?.getCenter();
    if (currentPosition) {
      const googleMapCenterLocation: google.maps.LatLngLiteral = {
        lng: currentPosition.lng(),
        lat: currentPosition.lat(),
      };
      setGoogleMapCenter(googleMapCenterLocation);
      const lastMapCenter =
        currentMapCenterCoordinates?.length === 2
          ? {
              lng: currentMapCenterCoordinates[0],
              lat: currentMapCenterCoordinates[1],
            }
          : computeGoogleMapHome();
      const distance = calculateDistanceInMiles(lastMapCenter, googleMapCenterLocation);

      const zoomLevel = map?.getZoom();
      const threshold =
        isDefined(zoomLevel) && facilityDistanceThresholdConfig?.[zoomLevel]
          ? facilityDistanceThresholdConfig?.[zoomLevel]
          : facilityDistanceThresholdConfig?.default;

      setFindShiftsButtonEnabled(distance > (threshold ?? 150));
    }
  };

  const onDragEndOrOnZoomChanged = () => {
    setIsShowHomePinDesc(false);
    if (isNoFacilityMapAlertEnabled) {
      onBoundsChanged();
    }
    makeMapLogger();
    onMapCenterChanged();
    setGoogleMapZoomLevel(map?.getZoom() ?? 10);
  };

  const makeMapLogger = () => {
    if (!facilityShiftsAreLoading && map && isDoneInitBounds) {
      const homePoint = computeGoogleMapHome();
      const currentMapCenter = {
        lat: map.getCenter()?.lat() || 0,
        lng: map.getCenter()?.lng() || 0,
      };
      let distance = 0;
      if (JSON.stringify(homePoint) !== JSON.stringify(defaultlongLat)) {
        distance = calculateDistanceInMiles(homePoint, currentMapCenter);
      }

      logEvent(USER_EVENTS.MOVED_MAP, {
        "location.lat": currentMapCenter.lat,
        "location.lng": currentMapCenter.lng,
        distance,
        zoom: map.getZoom() as unknown as string,
      });
    }
  };

  const convertBounds = (unreadablebounds: google.maps.LatLngBounds | undefined) => {
    const latLngBounds = {
      lat: {
        // the mapbounds returned .mc or .lc
        // 11.468 Southest lat of Texas, 71.218 Northest lat of Alaska
        min: 11.468,
        max: 71.218,
      },
      lng: {
        min: -164.383,
        max: -49.157,
      },
    };
    const NePoint = unreadablebounds?.getNorthEast();
    const SwPoint = unreadablebounds?.getSouthWest();
    if (!NePoint?.lat || !NePoint?.lng || !SwPoint?.lat || !SwPoint?.lng) {
      return latLngBounds;
    }
    latLngBounds.lat.min = SwPoint.lat() || latLngBounds.lat.min;
    latLngBounds.lat.max = NePoint.lat() || latLngBounds.lat.max;
    latLngBounds.lng.min = SwPoint.lng() || latLngBounds.lng.min;
    latLngBounds.lng.max = NePoint.lng() || latLngBounds.lng.max;
    return latLngBounds;
  };

  const areThereVisibleFacilitiesInBound = () => {
    const unreadablebounds = map?.getBounds();
    const bounds = convertBounds(unreadablebounds);
    return facilityShifts.some((facility) => {
      if ((facility?.geoLocation?.coordinates?.length as number) > 1) {
        const facilityLat = facility.geoLocation?.coordinates[1];
        const facilityLng = facility.geoLocation?.coordinates[0];

        if (!facilityLat || !facilityLng) {
          return false;
        }

        const isLatInsideBonds = bounds.lat.min <= facilityLat && facilityLat <= bounds.lat.max;
        const isLngInsideBonds = bounds.lng.min <= facilityLng && facilityLng <= bounds.lng.max;
        return isLatInsideBonds && isLngInsideBonds;
      }
      return false;
    });
  };

  const onOpenHomePinDesc = () => {
    setIsShowHomePinDesc(true);
  };
  const onCloseHomePinDesc = () => {
    setIsShowHomePinDesc(false);
  };
  const onClickMarker = useCallback(
    (facility: Facility) => {
      setIsShowHomePinDesc(false);
      onClickHcfMarker(facility);
    },
    [onClickHcfMarker]
  );

  if (mapLoadError || (!isMapLoaded && !facilityShiftsAreLoading)) {
    return (
      <div style={{ textAlign: "center", marginTop: "10em" }}>
        The map cannot be loaded right now. Please close and reopen the app
      </div>
    );
  }

  if (!isMapLoaded) {
    return (
      <div style={{ textAlign: "center", marginTop: "10em" }}>
        <InlineLoader loading={true} />
      </div>
    );
  }
  const isFindShiftsButtonStackEnabled = isBrowsingShiftsEnabled && findShiftsButtonEnabled;

  const basePath = process.env.REACT_APP_BASE_PATH ?? "/";

  return (
    <>
      <GoogleMap
        onLoad={onLoadGoogleMap}
        mapContainerStyle={googleMapContainerStyle}
        options={googleMapOptions}
        onIdle={onDragEndOrOnZoomChanged}
        onClick={onCloseHomePinDesc}
      >
        {currentLocation.lat && currentLocation.lng && (
          <Marker
            icon={`${markersSourcePath}/currentLocation.png`}
            position={currentLocation}
            zIndex={1}
          />
        )}
        {worker.geoLocation?.coordinates && (
          <>
            {isShowHomePinDesc && (
              <OverlayView
                position={computeGoogleMapHome()}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div className="home-pin-overlay">
                  <div className="pin-box">
                    <h5>My Home</h5>
                    <div>{formatAgentAddress(worker)}</div>
                  </div>
                  <div className="pin-arrow"></div>
                </div>
              </OverlayView>
            )}
            <Marker
              icon={`${markersSourcePath}/home.png`}
              position={computeGoogleMapHome()}
              onClick={onOpenHomePinDesc}
              zIndex={1}
            />
          </>
        )}
        <MemoizedFacilitiesMarkers facilityShifts={facilityShifts} onClickMarker={onClickMarker} />
      </GoogleMap>
      {isFindShiftsButtonStackEnabled && (
        <Stack
          spacing={2}
          sx={{
            /**
             * FIXME - some of these styles should really be in a parent Box wrapper.
             */
            textTransform: "none",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "100px",
            maxWidth: "208px",
          }}
        >
          {isBrowsingShiftsEnabled && findShiftsButtonEnabled && (
            <Button
              aria-label="Find shifts here"
              variant="contained"
              endIcon={
                <IonImg className="find-shifts-icon" src={`${basePath}assets/icons/tap.png`} />
              }
              sx={{
                /**
                 * FIXME - refactor all this custom styling to remove unneeded overrides.
                 * Use standard button styles.
                 */
                display: "flex",
                justifyContent: "space-between",
                background: "#5DA0FB",
                border: "1px solid #FFFFFF",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                padding: "8px 20px",
                "&:hover, &:active, &:focus": {
                  background: "#5DA0FB",
                },
                /** FIXME - remove this class styling and style the image directly. */
                "& .find-shifts-icon": {
                  width: "24px",
                  height: "24px",
                  paddingBottom: "2px",
                },
              }}
              onClick={() => {
                try {
                  const currentPosition = map?.getCenter();
                  if (currentPosition) {
                    onClickFindShiftsButton([currentPosition.lng(), currentPosition.lat()]);
                    setFindShiftsButtonEnabled(false);
                    logEvent(USER_EVENTS.FIND_SHIFTS_HERE_BUTTON_CLICKED, {
                      hcpId: worker.userId,
                      qualification: worker.preference?.qualification,
                      currentPosition: { lat: currentPosition.lat(), lng: currentPosition.lng() },
                      currentMapCenterCoordinates,
                    });
                    zoomInAfterFindingShifts.current = true;
                  }
                } catch (error) {
                  deprecatedDoNotUseLogError({
                    message: `Error while getting map center ${JSON.stringify(
                      error?.stack || error
                    )}`,
                  });
                }
              }}
            >
              Find shifts here
            </Button>
          )}
        </Stack>
      )}
    </>
  );
}

/**
 * FIXME Extract to ./utils/ and add interface and tests
 * https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
 */
const calculateDistanceInMiles = (point0, point1) => {
  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = point0.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = point1.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (point1.lng - point0.lng) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
      )
    );
  return d;
};

/**
 * FIXME Extract to ./utils/ and add interface and tests
 */
const mapHcfDistanceAndSortSplice = ({ facilityShifts, center }) => {
  const facilitiesWithDistance: {
    lat: number;
    lng: number;
    distance: number;
    _id: string | undefined;
    userId: string | undefined;
    name: string | undefined;
    shiftsCount: number | undefined;
  }[] = [];
  facilityShifts?.forEach((facility: Facility) => {
    const facilityLat = facility?.geoLocation?.coordinates[1] || 0;
    const facilityLng = facility?.geoLocation?.coordinates[0] || 0;
    const distance = calculateDistanceInMiles(center, {
      lat: facilityLat,
      lng: facilityLng,
    });
    if (distance > 0 && distance <= 100 && (facility.shiftsCount as number) > 0) {
      facilitiesWithDistance.push({
        lat: facilityLat,
        lng: facilityLng,
        distance: distance,
        _id: facility._id,
        userId: facility.userId,
        name: facility.name,
        shiftsCount: facility.shiftsCount,
      });
    }
  });
  facilitiesWithDistance.sort((a, b) => a.distance - b.distance);
  if (facilitiesWithDistance.length >= 5) {
    // get top 5 nearest
    facilitiesWithDistance.splice(5, facilitiesWithDistance.length - 4);
  }
  return facilitiesWithDistance;
};

/**
 * FIXME Extract to ./utils/ and add interface and tests
 */
interface MakeMapBoundsIntoHcfProps {
  facilityShifts: GoogleMapShiftsProps["facilityShifts"];
  center: google.maps.LatLngLiteral;
  map: google.maps.Map;
  googleMaps: typeof google.maps;
}
function makeMapBoundsIntoHcf(props: MakeMapBoundsIntoHcfProps) {
  const { facilityShifts, center, map, googleMaps } = props;
  const facilitiesWithDistance = mapHcfDistanceAndSortSplice({
    facilityShifts,
    center,
  });

  const bounds = new googleMaps.LatLngBounds();

  let gmapLatLong = new googleMaps.LatLng(center.lat, center.lng);
  bounds.extend(gmapLatLong);
  facilitiesWithDistance.forEach((facility) => {
    gmapLatLong = new googleMaps.LatLng(facility.lat, facility.lng);
    bounds.extend(gmapLatLong);
  });

  map.fitBounds(bounds, 30);

  const oldZoom = map.getZoom();
  if (!isDefined(oldZoom) || oldZoom < 6 || oldZoom >= 18) {
    // handle web behavior that show global map when fitBounds
    // Note this doesn't work, because fitBounds acts asynchronously.
    map.setZoom(10);
  }
}
